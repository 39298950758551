<template>
  <div>
    <b-card>
      <template #header>
        <h3 class="text-capitalize">{{ $t('label.characterDetails') }}</h3>
      </template>

      <div v-if="value.castingRoles">
        <template v-for="(item, index) in value.castingRoles">
          <div :key="index">
            <b-card-title>{{
              `${$t('label.character')} ${index + 1}`
            }}</b-card-title>

            <AppDescription
              :contentHolder="characterDetailList"
              :value="item"
              type="default"
            >
              <!-- begin:: Gender -->
              <template v-slot:gender>
                <span class="text-capitalize">
                  {{
                    value.gender == 'other'
                      ? value.genderOther
                      : value.gender || '-'
                  }}</span
                >
              </template>
              <!-- end:: Gender -->

              <!-- begin:: Casting Script File -->
              <template v-slot:castingScriptFile v-if="castingScriptFile">
                <div
                  class="d-flex justify-content-between align-items-center border rounded py-2 px-4"
                >
                  <div>
                    <i class="flaticon-attachment mr-4"></i>
                    <span class="mb-0 text-capitalize">{{
                      $t('label.castingScript')
                    }}</span>
                  </div>

                  <AppButtonDocumentView
                    :attachmentUrl="item.castingScriptFile.mediaPath"
                  ></AppButtonDocumentView>
                </div>
              </template>
              <!-- end:: Casting Script File -->

              <!-- begin:: Applications -->
              <template
                v-for="(type, typeIndex) in applicationTypes"
                v-slot:[type.property]
              >
                <div :key="typeIndex">
                  <span
                    v-if="item[type.property]"
                    class="text-primary cursor-pointer"
                    @click="
                      getPostJobApplicationDetails(
                        `modal-${type.type}-${index}`
                      )
                    "
                    >{{ item[type.property] }}</span
                  >
                  <span v-else>{{ '-' }}</span>

                  <PostJobApplicationsModal
                    :ref="`modal-${type.type}-${index}`"
                    :value="item"
                    :allApplications="allApplications"
                    :currentType="type.type"
                    :types="applicationTypes"
                  ></PostJobApplicationsModal>
                </div>
              </template>
              <!-- end:: Applications -->
            </AppDescription>
          </div>
          <div
            v-if="value.castingRoles.length > 1"
            :key="'separator-' + index"
            class="separator separator-dashed my-10"
          ></div>
        </template>
      </div>
      <div v-else>
        <span>{{ $t('message.thereAreNoInformationToShow') }}</span>
      </div>
    </b-card>
  </div>
</template>

<script>
  import AppDescription from '@/views/components/bases/AppDescription';
  import AppButtonDocumentView from '@/views/components/bases/button/AppButtonDocumentView';
  import PostJobApplicationsModal from '@/views/components/post/PostJobApplicationsModal';
  import { i18nHelper } from '@/core/utils';
  import commonMixin from '@/core/mixins/common.mixin';
  import {
    CASTING_JOBS_GET_CASTING_JOB_APPLICATIONS,
    CASTING_JOBS_INITIAL_GET_CASTING_JOB_APPLICATIONS_STATE
  } from '@/core/store/casting-jobs.module';

  export default {
    name: 'PostCharacterDetailsCard',
    components: {
      AppDescription,
      AppButtonDocumentView,
      PostJobApplicationsModal
    },
    mixins: [commonMixin],
    props: {
      value: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({
      postJobApplicationsModal: null,
      allApplications: null,
      characterDetailList: [
        {
          head: i18nHelper.getMessage('label.character'),
          body: {
            key: 'character'
          }
        },
        {
          head: i18nHelper.getMessage('label.characterWriteUp'),
          body: {
            key: 'characterWriteUp'
          }
        },
        {
          head: i18nHelper.getMessage('label.castingScript'),
          body: {
            key: 'castingScriptFile',
            slotName: 'castingScriptFile'
          }
        },
        {
          head: i18nHelper.getMessage('label.sex'),
          body: {
            key: 'gender'
          }
        },
        {
          head: i18nHelper.getMessage('label.onScreenAge'),
          body: {
            key: 'ageRange'
          }
        },
        {
          head: i18nHelper.getMessage('label.skills'),
          body: {
            key: 'skills'
          }
        },
        {
          head: i18nHelper.getMessage('label.age'),
          body: {
            key: 'age'
          }
        },
        {
          head: i18nHelper.getMessage('label.race'),
          body: {
            key: 'race'
          }
        },
        {
          head: i18nHelper.getMessage('label.languagesSpoken'),
          body: {
            key: 'languagesSpoken'
          }
        },
        {
          head: i18nHelper.getMessage('label.noOfShootDays'),
          body: {
            key: 'shootingDays'
          }
        },
        {
          head: i18nHelper.getMessage('label.height'),
          body: {
            key: 'height'
          }
        },
        {
          head: i18nHelper.getMessage('label.location'),
          body: {
            key: 'location'
          }
        },
        {
          head: i18nHelper.getMessage('label.fees'),
          body: {
            key: 'fees'
          }
        },
        {
          head: i18nHelper.getMessage('label.numberOfOpenings'),
          body: {
            key: 'openingNumber'
          }
        },
        {
          head: i18nHelper.getMessage('label.numberOfApplicants'),
          body: {
            key: 'totalApplicants',
            slotName: 'totalApplicants'
          }
        },
        {
          head: i18nHelper.getMessage('label.numberOfPendingApplicants'),
          body: {
            key: 'totalPendingApplicants',
            slotName: 'totalPendingApplicants'
          }
        },
        {
          head: i18nHelper.getMessage('label.numberOfMaybeApplicants'),
          body: {
            key: 'totalMaybeApplicants',
            slotName: 'totalMaybeApplicants'
          }
        },
        {
          head: i18nHelper.getMessage('label.numberOfConfirmedApplicants'),
          body: {
            key: 'totalAcceptedApplicants',
            slotName: 'totalAcceptedApplicants'
          }
        },
        {
          head: i18nHelper.getMessage('label.numberOfDeclinedApplicants'),
          body: {
            key: 'totalDeclinedApplicants',
            slotName: 'totalDeclinedApplicants'
          }
        },
        {
          head: i18nHelper.getMessage('label.numberOfCancelledApplicants'),
          body: {
            key: 'totalCancelledApplicants',
            slotName: 'totalCancelledApplicants'
          }
        }
      ],
      applicationTypes: [
        {
          type: 'total',
          property: 'totalApplicants'
        },
        {
          type: 'pending',
          property: 'totalPendingApplicants'
        },
        {
          type: 'maybe',
          property: 'totalMaybeApplicants'
        },
        {
          type: 'accepted',
          property: 'totalAcceptedApplicants'
        },
        {
          type: 'declined',
          property: 'totalDeclinedApplicants'
        },
        {
          type: 'cancelled',
          property: 'totalCancelledApplicants'
        }
      ]
    }),
    computed: {
      castingJobApplicationsComplete() {
        return this.$store.state.castingJobs.castingJobApplications.complete;
      }
    },
    watch: {
      castingJobApplicationsComplete() {
        let response = this.$store.state.castingJobs.castingJobApplications;
        let title = i18nHelper.getMessage('label.getCastingJobApplications');
        let initialStateAction =
          CASTING_JOBS_INITIAL_GET_CASTING_JOB_APPLICATIONS_STATE;
        let successAction = (response) => {
          this.allApplications = response.data;
          this.openPostJobApplicationsModal();
        };

        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      }
    },
    methods: {
      openPostJobApplicationsModal() {
        this.$refs[this.postJobApplicationsModal][0].show();
      },
      getPostJobApplicationDetails(ref) {
        this.getCastingJobApplications(this.value.jobId);
        this.postJobApplicationsModal = ref;
      },
      getCastingJobApplications(id) {
        this.$store.dispatch(CASTING_JOBS_GET_CASTING_JOB_APPLICATIONS, { id });
      }
    }
  };
</script>

<style></style>
