<template>
  <div class="input-container">
    <div class="icon-container" @click="() => toggleEditMode(true)">
      <inline-svg src="/media/svg/icons/Design/Edit.svg" />
    </div>
    <input
      v-model="inputValue"
      :disabled="!editMode"
      type="text"
      class="w-100 p-inputtext p-component custom-field"
      @input="validateAndFormatInput"
    />
    <div v-if="editMode" class="icon-container icon-check" @click="onSave">
      <inline-svg src="/media/svg/icons/Navigation/Check.svg" />
    </div>
    <div
      v-if="editMode"
      class="icon-container icon-close"
      @click="() => toggleEditMode(false)"
    >
      <inline-svg src="/media/svg/icons/Navigation/Close.svg" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CustomApplicationFeeInput',
    props: {
      value: {
        type: Number,
        default: 0
      }
    },
    data: () => ({
      editMode: false,
      inputValue: ''
    }),
    mounted() {
      const vm = this;
      vm.$nextTick(function () {
        setTimeout(function () {
          const newInputValue = vm.$props.value
            ? vm.$props.value.toFixed(2)
            : '0.00';
          vm.inputValue = newInputValue;
        }, 1000);
      });
    },
    methods: {
      toggleEditMode(value) {
        if (!value) {
          this.inputValue =
            this.$props.value === null ? '0.00' : this.$props.value.toFixed(2);
        }
        this.editMode = value;
      },
      validateAndFormatInput(event) {
        let value = event.target.value;

        // Remove any non-digit or non-dot characters
        value = value.replace(/[^\d.]/g, '');

        // Ensure only one dot
        const parts = value.split('.');
        if (parts.length > 2) {
          value = parts[0] + '.' + parts.slice(1).join('');
        }

        // Limit to two decimal places
        if (parts[1]) {
          value = parts[0] + '.' + parts[1].slice(0, 2);
        }

        this.inputValue = value;
      },
      onSave() {
        const value = parseFloat(this.inputValue).toFixed(2);
        this.$emit('save', parseFloat(value));
        this.editMode = false;
      }
    }
  };
</script>

<style scoped>
  .input-container {
    display: flex;
    border-radius: 0.25rem;
    overflow: hidden;
  }

  .icon-container {
    --container-size: 34.5px;
    display: flex;
    align-items: center;
    justify-content: center;

    width: var(--container-size);
    height: var(--container-size);

    background-color: rgb(184, 184, 184);
    cursor: pointer;
  }
  .icon-container.icon-check {
    background-color: rgb(70, 214, 70);
  }
  .icon-container.icon-close {
    background-color: rgb(255, 74, 74);
  }
  .icon-container > svg {
    width: var(--container-size);
  }

  .custom-field {
    border-radius: 0;
  }
</style>
