<template>
  <b-card class="post-overview-job">
    <h3 class="text-capitalize h2 mb-8">{{ $t('label.overview') }}</h3>

    <div class="d-flex mb-8">
      <div>
        <AppMediaViewer
          v-if="castingJobObj.jobCoverFile"
          :value="castingJobObj.jobCoverFile"
          imageClass="post-overview-job__job-cover-file"
        ></AppMediaViewer>

        <b-avatar
          v-else
          square
          :variant="avatarVariant"
          size="72px"
          :text="shortTitle"
        ></b-avatar>
      </div>
      <div class="p-4">
        <div class="font-weight-bolder h4">
          {{ castingJobObj.title }}
        </div>
        <div class="text-gray-600">
          {{ castingJobObj.producer }}
        </div>
      </div>
    </div>
    <div class="mb-8">
      <template v-for="(i, index) in basicInfoList">
        <div :key="i + index" class="mb-4">
          <div class="font-weight-bolder text-capitalize mb-1">
            {{ $t(`label.${i.name}`) }}
          </div>
          <div class="text-end text-gray-600" :class="i.class">
            {{ castingJobObj[i.key] || '-' }}
          </div>
        </div>
      </template>
    </div>

    <b-list-group class="post-details_item-list-group">
      <template v-for="(item, index) in postDetailsItems">
        <b-list-group-item
          :key="index"
          button
          :active="value == item.key"
          @click="$emit('input', item.key)"
        >
          <b-row no-gutters>
            <b-col cols="2">
              <i class="icon mr-4" :class="item.icon"></i
            ></b-col>
            <b-col cols="auto" class="text-capitalize">
              {{ $t(`label.${item.name}`) }}</b-col
            >
          </b-row>
        </b-list-group-item>
      </template>
    </b-list-group>
  </b-card>
</template>

<script>
  import AppMediaViewer from '@/views/components/bases/AppMediaViewer';

  export default {
    name: 'PostOverviewCard',
    components: {
      AppMediaViewer
    },
    props: {
      value: {
        type: Number,
        default: 1
      },
      castingJobObj: {
        type: Object,
        default: () => {}
      },
      postDetailsItems: {
        type: Array,
        default: () => []
      }
    },
    data: () => ({
      basicInfoList: [
        {
          key: 'jobType',
          name: 'jobType',
          class: 'text-capitalize'
        },
        {
          key: 'projectType',
          name: 'projectType'
        }
      ]
    }),
    computed: {
      shortTitle() {
        let title = this.castingJobObj.title;
        let titleArray = title ? title.split(' ') : '';
        let shortTitle = '';

        if (title) {
          if (titleArray.length > 1) {
            shortTitle = titleArray[0].charAt(0) + titleArray[1].charAt(0);
          } else {
            shortTitle = titleArray[0].charAt(0);
          }
        }

        return shortTitle;
      },
      avatarVariant() {
        const variants = [
          'primary',
          'secondary',
          'danger',
          'warning',
          'success',
          'info'
        ];

        let random = Math.floor(Math.random() * variants.length);

        return random, variants[random];
      }
    }
  };
</script>

<style lang="scss">
  .post-details_item-list-group {
    .list-group-item.list-group-item-action.active {
      background: unset !important;
      border-color: unset !important;
      color: var(--primary);
      .icon {
        color: var(--primary);
      }
    }
  }

  .post-overview-job {
    .post-overview-job__job-cover-file {
      height: 72px !important;
      width: 72px !important;
      object-fit: cover !important;
    }
  }
</style>
