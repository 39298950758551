<template>
  <b-modal
    centered
    no-close-on-backdrop
    hide-header-close
    size="xl"
    :visible="visible"
    :title="title"
    header-class="text-capitalize"
    body-class="post-job-applications-modal"
    @ok="okAction"
    @cancel="cancelAction"
  >
    <div class="max-h-400px overflow-y-scroll px-2">
      <template v-for="(item, index) in applications">
        <div :key="index" class="mb-8">
          <h4 class="font-weight-bold">
            {{ $t('label.application') }} {{ index + 1 }}
          </h4>
          <div class="separator separator-dashed mb-4"></div>
          <div>
            <template v-for="(desc, descIndex) in descriptions">
              <b-row
                :key="descIndex"
                no-gutters
                class="descriptions-row px-10 py-5"
              >
                <b-col cols="12" md="4">
                  <label class="font-weight-bold">{{
                    $t(`label.${desc.label}`)
                  }}</label>
                </b-col>
                <b-col cols="12" md="8">
                  <component
                    v-if="desc.component && desc.key !== 'video'"
                    :is="desc.component"
                    :value="item[desc.key]"
                    :toDetailsRouteName="routeName.PROFILE_DETAILS_DETAILS"
                  >
                  </component>
                  <video 
                    v-else-if="desc.component && desc.key === 'video' && item[desc.key]"
                    controls
                  >
                    <source :src="item[desc.key]">
                  </video>
                  <div v-else :class="desc.class">
                    {{ item[desc.key] || '-' }}
                  </div>
                </b-col>
              </b-row>
            </template>
          </div>
        </div>
      </template>
    </div>
  </b-modal>
</template>

<script>
  import AppFeatureDetailsRelatedButton from '@/views/components/bases/AppFeatureDetailsRelatedButton';
  import { i18nHelper, uiHelper } from '@/core/utils';
  import { ROUTE_NAME } from '@/core/constants';

  export default {
    name: 'PostJobApplicationsModal',
    components: {
      AppFeatureDetailsRelatedButton
    },
    props: {
      value: {
        type: Object,
        default: () => ({})
      },
      allApplications: {
        type: Object,
        default: () => ({})
      },
      currentType: {
        type: String,
        default: null
      },
      types: {
        type: Array,
        default: () => []
      }
    },
    data: () => ({
      routeName: ROUTE_NAME,
      visible: false,
      descriptions: [
        {
          label: 'character',
          key: 'castingRoleCharacter'
        },
        {
          label: 'characterWriteUp',
          key: 'castingRoleCharacterWrite_up'
        },
        {
          label: 'fullName',
          key: 'talentFullName',
          component: 'AppFeatureDetailsRelatedButton'
        },
        {
          label: 'email',
          key: 'talentEmail',
        },
        {
          label: 'phoneNumber',
          key: 'talentMobileNum',
        },
        {
          label: 'video',
          key: 'video',
          component: 'video'
        },
        // {
        //   label: 'firstName',
        //   key: 'talentFirstName'
        // },
        // {
        //   label: 'lastName',
        //   key: 'talentLastName'
        // },
        {
          label: 'videoStatus',
          key: 'videoStatus',
          class: 'text-capitalize'
        },
        {
          label: 'videoStatusRemark',
          key: 'videoStatusRemark'
        },
        {
          label: 'statusRemark',
          key: 'statusRemark'
        }
      ]
    }),
    computed: {
      applications() {
        var data = this.allApplications;
        var currentType = this.currentType;
        var types = this.types;
        var id = this.value.id;
        var applications = [];

        if (data) {
          if (currentType == 'total') {
            types.forEach((x) => {
              if (data[x.type]) {
                let value = data[x.type].filter((y) => y.castingRoleId == id);

                applications = applications.concat(value);
              }
            });
          } else {
            applications = data[currentType].filter(
              (x) => x.castingRoleId == id
            );
          }
        }

        return applications;
      },
      title() {
        const title = {
          total: 'applications',
          pending: 'pendingApplications',
          maybe: 'maybeApplications',
          accepted: 'confirmedApplications',
          declined: 'declinedApplications',
          cancelled: 'cancelledApplications'
        };

        return i18nHelper.getMessage(`label.${title[this.currentType]}`);
      }
    },
    watch: {
      value() {
        if (this.value) this.visible = this.value;
      },
      visible() {
        if (this.visible) {
          uiHelper.freezeBodyOverflowY();
        } else {
          uiHelper.unFreezeBodyOverflowY();
        }
      }
    },
    methods: {
      show() {
        this.visible = true;
      },
      okAction() {
        this.closeModal();
      },
      cancelAction() {
        this.closeModal();
      },
      closeModal() {
        this.visible = false;
      }
    }
  };
</script>

<style lang="scss">
  .post-job-applications-modal {
    .descriptions-row {
      &:nth-child(odd) {
        background-color: #f8fbfb;
      }
    }
  }
</style>
