<template>
  <div>
    <b-card>
      <template #header>
        <h3 class="text-capitalize">{{ $t('label.jobInformation') }}</h3>
      </template>

      <AppDescription
        :contentHolder="jobInformationNewList"
        :value="value"
        type="default"
      >
        <template v-slot:isPaid>
          <AppCheckCircle :value="Boolean(value.isPaid)"></AppCheckCircle>
        </template>

        <template v-slot:isWfh>
          <AppCheckCircle :value="Boolean(value.isWfh)"></AppCheckCircle>
        </template>

        <template slot="visibility">
          <AppFormField
            v-model="value.visibility"
            className="checkbox_field"
            formType="checkbox"
            :id="`visibility-id`"
            :switches="true"
            size="lg"
            solid
            noTitle
            @input="onVisibilityChange"
          />
        </template>

        <template slot="applicationFeeCheckbox">
          <CustomApplicationFeeInput
            :value="value.applicationFees"
            @save="onApplicationFeeSave"
          />
        </template>

        <template slot="unionNo">
          <AppFormField
            v-model="value.unionNo"
            className="checkbox_field"
            formType="checkbox"
            :id="`unionNo-id`"
            :switches="true"
            size="lg"
            solid
            noTitle
            @input="onUnionNoChange"
          />
        </template>
      </AppDescription>
    </b-card>
  </div>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import AppDescription from '@/views/components/bases/AppDescription';
  import AppCheckCircle from '@/views/components/bases/AppCheckCircle';
  import CustomApplicationFeeInput from '@/views/inputs/CustomApplicationFeeInput.vue';
  import { i18nHelper } from '@/core/utils';
  import {
    CASTING_JOBS_UPDATE_CASTING_JOBS_VISIBILITY,
    CASTING_JOBS_UPDATE_CASTING_JOBS_APPLICATION_FEE,
    CASTING_JOBS_UPDATE_CASTING_JOBS_UNION_NO
  } from '@/core/store/casting-jobs.module';

  export default {
    name: 'PostJobInfoCard',
    components: {
      AppFormField,
      AppDescription,
      AppCheckCircle,
      CustomApplicationFeeInput
    },
    props: {
      value: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({
      personalInformationList: [
        {
          head: i18nHelper.getMessage('label.title'),
          body: {
            key: 'title'
          }
        },
        {
          head: i18nHelper.getMessage('label.producer'),
          body: {
            key: 'producer'
          }
        },
        {
          head: i18nHelper.getMessage('label.projectType'),
          body: {
            key: 'projectType'
          }
        },
        {
          head: i18nHelper.getMessage('label.jobType'),
          body: {
            key: 'jobType',
            class: 'text-capitalize'
          }
        },
        {
          head: i18nHelper.getMessage('label.jobCountry'),
          body: {
            key: 'jobCountryId'
          }
        },
        {
          head: i18nHelper.getMessage('label.countryOfApplicant'),
          body: {
            key: 'applicantCountryId'
          }
        },
        {
          head: i18nHelper.getMessage('label.castingClosingDate'),
          body: {
            key: 'closeDate'
          }
        },
        {
          head: i18nHelper.getMessage('label.postingGoesLiveOn'),
          body: {
            key: 'liveDate'
          }
        },
        {
          head: i18nHelper.getMessage('label.paid'),
          body: {
            key: 'isPaid',
            slotName: 'isPaid'
          }
        },
        {
          head: i18nHelper.getMessage('label.Wfh'),
          body: {
            key: 'isWfh',
            slotName: 'isWfh'
          }
        },
        {
          head: i18nHelper.getMessage('label.visibility'),
          body: {
            key: 'visibility',
            slotName: 'visibility',
            isComponent: true
          }
        },
        {
          head: i18nHelper.getMessage('label.applicationFee'),
          body: {
            key: 'applicationFeeCheckbox',
            slotName: 'applicationFeeCheckbox',
            isComponent: true
          }
        },
        {
          head: i18nHelper.getMessage('label.unionNo'),
          body: {
            key: 'unionNo',
            slotName: 'unionNo',
            isComponent: true
          }
        }
      ]
    }),
    computed: {
      jobInformationNewList() {
        let newList = [];

        if (
          ['talent_agency', 'production_corporate_agency'].includes(
            this.userRole
          )
        ) {
          newList = this.personalInformationList;
        } else {
          newList = this.personalInformationList.filter(
            (x) => x.body.key != 'companyCode'
          );
        }
        return newList;
      },
      isVisibilityActions() {
        return {
          module: 'castingJobs',
          state: 'castingJob',
          actionSet: CASTING_JOBS_UPDATE_CASTING_JOBS_VISIBILITY,
          errorActionL: () => {},
          title: i18nHelper.getMessage('label.updateCastingJobsVisibility')
        };
      },
      userRole() {
        return this.value.role
          ? this.value.role
              .toLowerCase()
              .replace(' ', '_')
              .replace('/', '_')
              .replace('/', '_')
          : null;
      },
      isUnionNoActions() {
        return {
          module: 'castingJobs',
          state: 'castingJob',
          actionSet: CASTING_JOBS_UPDATE_CASTING_JOBS_UNION_NO,
          errorActionL: () => {},
          title: i18nHelper.getMessage('label.updateCastingJobsUnionNo')
        };
      }
    },
    methods: {
      onVisibilityChange(value) {
        const payload = { id: this.value.id, data: { visibility: value } };
        this.$store.dispatch(
          CASTING_JOBS_UPDATE_CASTING_JOBS_VISIBILITY,
          payload
        );
      },
      onApplicationFeeSave(value) {
        const payload = {
          id: this.value.id,
          data: { enable: value > 0 ? 1 : 0, application_fees: value }
        };
        this.$store.dispatch(
          CASTING_JOBS_UPDATE_CASTING_JOBS_APPLICATION_FEE,
          payload
        );
      },
      onUnionNoChange(value) {
        const payload = { id: this.value.id, data: { unionNo: value } };
        this.$store.dispatch(
          CASTING_JOBS_UPDATE_CASTING_JOBS_UNION_NO,
          payload
        );
      }
    }
  };
</script>

<style>
  .checkbox_field > .custom-control-input:checked ~ label::before {
    background-color: #1bc5bd !important;
  }

  .checkbox_field > label::before,
  .checkbox_field > label::after {
    width: 1.5rem !important;
    height: 1.5rem !important;
  }

  /* APPLICATION FEE */
  .application_fee_field {
    display: inline-flex;
  }
</style>
