<template>
  <div>
    <b-card>
      <template #header>
        <h3 class="text-capitalize">{{ $t('label.productionDetails') }}</h3>
      </template>

      <AppDescription
        :contentHolder="productionDetailList"
        :value="value"
        type="default"
      >
        <template v-slot:gender>
          <span class="text-capitalize">
            {{
              value.gender == 'other' ? value.genderOther : value.gender || '-'
            }}</span
          >
        </template>
      </AppDescription>
    </b-card>
  </div>
</template>

<script>
  import AppDescription from '@/views/components/bases/AppDescription';
  import { i18nHelper } from '@/core/utils';
  import { CONSTANTS_CONSTANTS } from '@/core/store/constants.module';

  export default {
    name: 'PostProductionDetailsCard',
    components: {
      AppDescription
    },
    props: {
      value: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({
      productionDetailList: []
    }),
    computed: {
      constants() {
        return this.$store.getters[CONSTANTS_CONSTANTS];
      }
    },
    mounted() {
      this.initPostDetailsExtraInformationCard();
    },
    methods: {
      initPostDetailsExtraInformationCard() {
        let constants = this.constants;
        let list = [];
        let dataSetId = constants.projectTypes.find(
          (x) => x.id == this.value.projectTypeId
        ).dataset_id;

        if (dataSetId == 1) {
          list = [
            {
              head: i18nHelper.getMessage('label.synopsis'),
              body: {
                key: 'synopsis'
              }
            },
            {
              head: i18nHelper.getMessage('label.productionPeriodFrom'),
              body: {
                key: 'productionPeriodFrom'
              }
            },
            {
              head: i18nHelper.getMessage('label.productionPeriodTo'),
              body: {
                key: 'productionPeriodTo'
              }
            },
            {
              head: i18nHelper.getMessage('label.noOfShootDays'),
              body: {
                key: 'shootingDays'
              }
            },
            {
              head: i18nHelper.getMessage('label.noOfHoursPerDay'),
              body: {
                key: 'hoursPerDay'
              }
            },
            {
              head: i18nHelper.getMessage('label.videoToBeSubmittedBy'),
              body: {
                key: 'videoDue'
              }
            }
          ];
        } else if (dataSetId == 2) {
          list = [
            {
              head: i18nHelper.getMessage('label.synopsis'),
              body: {
                key: 'synopsis'
              }
            },
            {
              head: i18nHelper.getMessage('label.productionPeriodFrom'),
              body: {
                key: 'productionPeriodFrom'
              }
            },
            {
              head: i18nHelper.getMessage('label.productionPeriodTo'),
              body: {
                key: 'productionPeriodTo'
              }
            },
            {
              head: i18nHelper.getMessage('label.loadingPeriod'),
              body: {
                key: 'loadingPeriod'
              }
            },
            {
              head: i18nHelper.getMessage('label.loadingPlatform'),
              body: {
                key: 'loadingPlatformAll'
              }
            },
            {
              head: i18nHelper.getMessage('label.territory'),
              body: {
                key: 'territory'
              }
            },
            {
              head: i18nHelper.getMessage('label.noOfShootDays'),
              body: {
                key: 'shootingDays'
              }
            },
            {
              head: i18nHelper.getMessage('label.noOfHoursPerDay'),
              body: {
                key: 'hoursPerDay'
              }
            },
            {
              head: i18nHelper.getMessage('label.videoToBeSubmittedBy'),
              body: {
                key: 'videoDue'
              }
            }
          ];
        } else if (dataSetId == 3) {
          list = [
            {
              head: i18nHelper.getMessage('label.synopsis'),
              body: {
                key: 'synopsis'
              }
            },
            {
              head: i18nHelper.getMessage('label.objective'),
              body: {
                key: 'objective'
              }
            },
            {
              head: i18nHelper.getMessage('label.targetAudience'),
              body: {
                key: 'targetAudience'
              }
            },
            {
              head: i18nHelper.getMessage('label.productionPeriodFrom'),
              body: {
                key: 'productionPeriodFrom'
              }
            },
            {
              head: i18nHelper.getMessage('label.productionPeriodTo'),
              body: {
                key: 'productionPeriodTo'
              }
            },
            {
              head: i18nHelper.getMessage('label.campaignDuration'),
              body: {
                key: 'campaignDuration'
              }
            },
            {
              head: i18nHelper.getMessage('label.noOfShootDays'),
              body: {
                key: 'shootingDays'
              }
            },
            {
              head: i18nHelper.getMessage('label.noOfHoursPerDay'),
              body: {
                key: 'hoursPerDay'
              }
            },
            {
              head: i18nHelper.getMessage('label.videoToBeSubmittedBy'),
              body: {
                key: 'videoDue'
              }
            }
          ];
        } else if (dataSetId == 4) {
          list = [
            {
              head: i18nHelper.getMessage('label.productionPeriodFrom'),
              body: {
                key: 'productionPeriodFrom'
              }
            },
            {
              head: i18nHelper.getMessage('label.productionPeriodTo'),
              body: {
                key: 'productionPeriodTo'
              }
            },
            {
              head: i18nHelper.getMessage('label.noOfShootDays'),
              body: {
                key: 'shootingDays'
              }
            },
            {
              head: i18nHelper.getMessage('label.noOfHoursPerDay'),
              body: {
                key: 'hoursPerDay'
              }
            },
            {
              head: i18nHelper.getMessage('label.loadingPeriod'),
              body: {
                key: 'loadingPeriod'
              }
            },
            {
              head: i18nHelper.getMessage('label.loadingPlatform'),
              body: {
                key: 'loadingPlatformAll'
              }
            },
            {
              head: i18nHelper.getMessage('label.territory'),
              body: {
                key: 'territory'
              }
            },
            {
              head: i18nHelper.getMessage('label.sex'),
              body: {
                key: 'gender',
                slotName: 'gender'
              }
            },
            {
              head: i18nHelper.getMessage('label.fees'),
              body: {
                key: 'fees'
              }
            },
            {
              head: i18nHelper.getMessage('label.numberOfOpenings'),
              body: {
                key: 'openingNumber'
              }
            },
            {
              head: i18nHelper.getMessage('label.videoToBeSubmittedBy'),
              body: {
                key: 'videoDue'
              }
            }
          ];
        }

        this.productionDetailList = list;
      }
    }
  };
</script>

<style></style>
