<template>
  <div>
    <!--begin: Details Section-->
    <b-row no-gutters class="w-100 mt-4">
      <!--begin: Col 1-->
      <b-col cols="12" md="4">
        <!-- begin: Overview -->
        <PostOverviewCard
          v-model="selectedPostDetailsItem"
          :castingJobObj="castingJobObj"
          :postDetailsItems="postDetailsItems"
          class="mb-6"
        ></PostOverviewCard>
        <!-- begin: Overview -->
      </b-col>
      <!--end: Col 1-->

      <!--begin: Col 2-->
      <b-col cols="12" md="8" class="mt-4 mt-md-0 pl-md-4 pl-lg-8">
        <template v-for="(item, index) in postDetailsItems">
          <component
            v-if="selectedPostDetailsItem == item.key"
            :key="index"
            :is="item.component"
            :value="castingJobObj"
            @refresh-profile-details="refreshProfileDetailsAction"
          >
          </component>
        </template>
      </b-col>
      <!--end Col 2-->
    </b-row>
    <!--end: Details Section-->
  </div>
</template>

<script>
  import PostOverviewCard from '@/views/components/post/PostOverviewCard';
  import PostJobInfoCard from '@/views/components/post/PostJobInfoCard';
  import PostProductionDetailsCard from '@/views/components/post/PostProductionDetailsCard';
  import PostCharacterDetailsCard from '@/views/components/post/PostCharacterDetailsCard';
  import commonMixin from '@/core/mixins/common.mixin';
  import { ROUTE_NAME, MODULE, ACTION } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import {
    CASTING_JOBS_GET_CASTING_JOB,
    CASTING_JOBS_INITIAL_GET_CASTING_JOB_STATE
  } from '@/core/store/casting-jobs.module';

  export default {
    name: 'PostDetails',
    mixins: [commonMixin],
    components: {
      PostOverviewCard,
      PostJobInfoCard,
      PostProductionDetailsCard,
      PostCharacterDetailsCard
    },
    data: () => ({
      routeName: ROUTE_NAME,
      moduleName: MODULE.USER,
      permissionAction: ACTION,
      id: null,
      castingJobObj: {},
      selectedPostDetailsItem: 1,
      postDetailsItems: [
        {
          name: 'jobInformation',
          key: 1,
          icon: 'fas fa-briefcase',
          component: 'PostJobInfoCard'
        },
        {
          name: 'productionDetails',
          key: 2,
          icon: 'fas fa-photo-video',
          component: 'PostProductionDetailsCard'
        },
        {
          name: 'characterDetails',
          key: 3,
          icon: 'fas fa-users',
          component: 'PostCharacterDetailsCard'
        }
      ]
    }),
    computed: {
      castingJobComplete() {
        return this.$store.state.castingJobs.castingJob.complete;
      },
      displayablePostDetailItems() {
        let newList = this.postDetailsItems;
        let data = this.castingJobObj;

        if (!data.guardianRelationship) {
          newList = newList.filter((x) => x.key != 2);
        }

        return newList;
      }
    },
    watch: {
      castingJobComplete() {
        let response = this.$store.state.castingJobs.castingJob;
        let title = i18nHelper.getMessage('label.getPostDetails');
        let initialStateAction = CASTING_JOBS_INITIAL_GET_CASTING_JOB_STATE;
        let successAction = (response) => {
          this.importCastingJobObj(response);
        };

        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      }
    },
    created() {
      this.initialPostDetails();
    },
    methods: {
      refreshProfileDetailsAction() {
        this.getCastingJob(this.id);
      },
      importCastingJobObj(response) {
        let data = response.data;

        this.castingJobObj = data;
      },
      getCastingJob(id) {
        this.$store.dispatch(CASTING_JOBS_GET_CASTING_JOB, {
          id
        });
      },
      initialPostDetails() {
        let id = Number(this.$route.params.id);
        let breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.post'),
              route: { name: ROUTE_NAME.POST }
            },
            { title: i18nHelper.getMessage('label.postDetails') }
          ],
          actions: [
            {
              text: i18nHelper.getMessage('label.back'),
              function: () => {
                this.$router.go(-1);
              }
            }
          ]
        };

        this.id = id;
        this.getCastingJob(id);
        this.initBreadCrumb(breadcrumbData);
        window.KTUtil.scrollTop();
      }
    }
  };
</script>

<style lang="scss"></style>
